import federatedRedux from 'federated-redux';
import * as Sentry from '@sentry/react';
import {
  init as productAnalyticsInit,
  ProductAnalyticsProviderOptions,
} from '@sosafe-platform-engineering/fe-lib-product-analytics';

import { deepFreeze, getAppDomain } from '@sosafe-aws/fe-lib-manager-shared/utils';

interface RuntimeConfig {
  [key: string]: string | boolean | number;
}

interface BuildData {
  version: string;
  date: string;
}

declare global {
  interface Window {
    rootRuntimeConfig: RuntimeConfig;
    rootBuild: BuildData;
    managerRuntimeConfig: RuntimeConfig;
    chmln: any;
    isRoutingV6: boolean;
  }
}

function fetchJson<T>(url: string, defaultResult: T, attempt = 1): Promise<T> {
  return fetch(url).then((res) => {
    if (res.ok) {
      return res.json();
    }

    if (attempt < 3) {
      console.warn(`Failed to fetch ${url}, running another attempt...`);
      return fetchJson(url, defaultResult, attempt + 1);
    }

    console.error(`Failed to fetch ${url}!`);
    console.error(res.statusText);
    return defaultResult;
  });
}

// Fetch the runtime config with several attempts
function getRuntimeConfig(): Promise<RuntimeConfig> {
  return fetchJson<RuntimeConfig>('/config.json', {});
}

// Alternatively fetch the runtime config from process.env
const getRuntimeConfigFromEnv = () =>
  Promise.resolve({
    API_URL: process.env.API_URL,
    API_V3_URL: process.env.API_V3_URL,
    ELEARNING_URL: process.env.ELEARNING_URL,
    LEARNING_PAGE_URL: process.env.LEARNING_PAGE_URL,
    IMAGES_URL: process.env.IMAGES_URL,
    SURVEY_HUB_API_URL: process.env.SURVEY_HUB_API_URL,
    STATUS_PAGE_URL: process.env.STATUS_PAGE_URL,
    // Deployment
    ENVIRONMENT: process.env.ENVIRONMENT,
    DEPLOY_ENVIRONMENT: process.env.DEPLOY_ENVIRONMENT,
    // Amplitude tracking environment variables
    AMPLITUDE_ACTIVATED: process.env.AMPLITUDE_ACTIVATED,
    AMPLITUDE_VERBOSE: process.env.AMPLITUDE_VERBOSE,
    AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
    // Chameleon environment variables
    CHAMELEON_ACTIVE: process.env.CHAMELEON_ACTIVE,
    CHAMELEON_URL: process.env.CHAMELEON_URL,
    CHAMELEON_API_KEY: process.env.CHAMELEON_API_KEY,
    CHAMELEON_BLACKLIST: process.env.CHAMELEON_BLACKLIST as string,
    CHAMELEON_SEARCH_BUTTON: process.env.CHAMELEON_SEARCH_BUTTON,
    // Sentry
    SENTRY_DSN: process.env.SENTRY_DSN,
    LAUNCHDARKLY_CLIENT_ID: process.env.LAUNCHDARKLY_CLIENT_ID,
  });

function fetchBuildData(): Promise<BuildData> {
  return fetchJson<BuildData>('/build.json', { version: 'unknown', date: 'unknown' });
}

function initialiseProductAnalytics(runtimeConfig: RuntimeConfig, buildData: BuildData) {
  const activated = runtimeConfig.AMPLITUDE_ACTIVATED === 'true';
  const apiKey = runtimeConfig.AMPLITUDE_API_KEY as string;
  const appVersion = buildData.version;
  const enableAnalytics = activated && !!apiKey && !!appVersion;
  const appName = 'manager-root';
  const verbose = runtimeConfig.AMPLITUDE_VERBOSE === 'true';

  const options: ProductAnalyticsProviderOptions = {
    apiKey: apiKey ?? 'missing api_key',
    appVersion: appVersion ?? '0.0.0',
    active: !!enableAnalytics,
    appName,
    verbose,
  };

  return productAnalyticsInit(options);
}

// Fetch configuration
const runtimeConfigPromise =
  process.env.USE_RUNTIME_CONFIG === 'true' ? getRuntimeConfig() : getRuntimeConfigFromEnv();

// Fetch build data
const buildDataPromise = fetchBuildData();

// Load federated redux dispatcher before app boots
const federatedDispatcherPromise = import('Manager/reduxFederatedDispatch').catch(() => {
  console.warn('Failed to load federated dispatcher');
  return {
    default: () => {
      console.warn('Federated Redux Dispatch was not loaded');
    },
  };
});

const getEnvString = (env: string) => {
  if (env === 'staging') {
    return '-stage';
  }
  if (env === 'production') {
    return '';
  }
  return `-dev`;
};

Promise.all([runtimeConfigPromise, buildDataPromise, federatedDispatcherPromise]).then(
  async ([runtimeConfig, buildData, { default: federatedDispatcher }]) => {
    // const {
    //   API_URL,
    //   API_V3_URL,
    //   ELEARNING_URL,
    //   LEARNING_PAGE_URL,
    //   IMAGES_URL,
    //   SURVEY_HUB_API_URL,
    //   STATUS_PAGE_URL,
    // } = runtimeConfig;
    // base_domain: getAppDomain(window.location.hostname), // TODO: uncomment this when we decide how to get the app domain from browser
    const baseDomain = getAppDomain(
      `https://manager.sosafe${getEnvString(runtimeConfig.DEPLOY_ENVIRONMENT)}.de`,
      runtimeConfig.DEPLOY_ENVIRONMENT,
    );
    // Set the runtime config for global usage
    window.rootRuntimeConfig = deepFreeze<RuntimeConfig>({
      ...runtimeConfig,
      // ...replaceDomain( // TODO: uncomment this when we decide how to get the app domain from browser
      //   {
      //     API_URL,
      //     API_V3_URL,
      //     ELEARNING_URL,
      //     LEARNING_PAGE_URL,
      //     IMAGES_URL,
      //     SURVEY_HUB_API_URL,
      //     STATUS_PAGE_URL,
      //   },
      //   appDomain,
      // ),
      base_domain: baseDomain || '',
    });

    // Freeze the runtime config to prevent it from being modified
    Object.freeze(window.rootRuntimeConfig);

    // Set build data
    window.rootBuild = buildData;

    // Set the federated redux dispatcher for local usage
    federatedRedux.dispatch = federatedDispatcher;

    const apiKey = runtimeConfig.AMPLITUDE_API_KEY as string;

    if (apiKey) {
      // Initialise product analytics
      await initialiseProductAnalytics(runtimeConfig, buildData);
    } else {
      console.error('Amplitude API key is missing');
      Sentry.captureMessage('Amplitude API key is missing');
    }

    // Let webpack load federated chunks asynchronously
    import(/* webpackChunkName: "root-bootstrap" */ './bootstrap');
  },
);

export {};
